<template>

    <!-- Toggle Button for Mobile -->
    <button class="toggle-btn" @click="toggleSidebar">
      ☰
    </button>

    <!-- Sidebar -->
    <div :class="['sidebar', { 'sidebar-collapsed': isCollapsed }]">
      <!-- Menu items -->
      <ul class="menu">
        <li v-for="item in menuItems" :key="item.id" class="menu-item">
              <router-link 
            :to="item.path" 
            :class="{ 'active': $route.path === item.path }"
            >
            <i :class="item.icon"></i> {{ item.label }}
          </router-link>
        </li>
      </ul>

      <!-- Social Links Section -->
     <p class="social-text">Follow Me</p> <!-- Text above social links -->
   <div class="social-links">
    <a 
      v-for="link in socialLinks" 
      :key="link.href" 
      :href="link.href" 
      target="_blank" 
      :aria-label="link.label">
      <i :class="link.icon"></i>
    </a>
  </div>
    </div>



</template>

<script>
export default {
  data() {
    return {
      isCollapsed: true, // Sidebar initially collapsed in mobile view
      menuItems: [
        // { label: 'Imran Rahman',path: "#" },
        { id: 1, label: "Home", path: "/", icon: "fa fa-home" },
        { id: 2, label: "About Me", path: "/about-us", icon: "fa fa-info-circle" },
        { id: 3, label: "My Projects", path: "/projects", icon: "fa fa-briefcase" },
        { id: 3, label: "My Experience", path: "/my-cv", icon: "fa fa-certificate" },
        { id: 4, label: "Reached Me", path: "/contactpage", icon: "fa fa-phone" },
      ],
       socialLinks: [
         // { href: "https://github.com/your-github-profile",icon: "fa fa-github", label: 'Coonect Social Links',path: "#" },
        { href: "https://github.com/imranbanglaweb", target:"_blank", icon: "fa fa-github", label: "GitHub" },
        { href: "https://www.linkedin.com/in/imran789", target:"_blank", icon: "fa fa-linkedin", label: "LinkedIn"},
        { href: "https://twitter.com/your-twitter-profile", icon: "fa fa-twitter", label: "Twitter" },
        { href: "https://www.facebook.com/imran.rahman.338", icon: "fa fa-facebook", label: "Facebook", target:"_blank" },
      ],
    };
  },
  methods: {
    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed; // Toggle the collapsed state
    },
  },
};
</script>

<style scoped>
/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Active Class Styling */
.menu-item a.active {
  background-color: #444; /* Active state background color */
  color: #fff; /* Active state text color */
  display: block;
  padding-bottom:  15px;
  padding-top:  10px;
    background: linear-gradient(to bottom, #5f9ea0, #000);
    vertical-align: middle;
    text-align: center;
}

/* Sidebar Styles */
.sidebar {
  /*width: 3200px; */
  height: 100vh;
  background: #333;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out, width 0.3s ease;
  z-index: 10;
  /*padding-top: 60px; */
}

/* Sidebar Menu Styles */
.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}
.menu i {
  color: yellowgreen
}
.menu-item {
  /*padding: 15px 0px;*/

}

.menu-item a {
  color: white;
  text-decoration: none;
  padding: 15px;
  border-bottom: 1px solid #666;
  display: block;
}

.menu-item a:hover {
  /*text-decoration: underline;*/
}

/* Sidebar Collapsed */
.sidebar-collapsed {
  transform: translateX(-100%); /* Hide the sidebar off-screen */
}

/* Toggle Button for Mobile */
.toggle-btn {
  background: #444;
  border: none;
  color: white;
  font-size: 15px;
  padding: 10px 15px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 20;
  display: none; /* Hidden by default on larger screens */
}

/* Content Area */
.content {
  margin-left: 250px; /* Default space for sidebar */
  transition: margin-left 0.3s ease-in-out;
}

.content-expanded {
  margin-left: 0; /* When sidebar is collapsed */
}




/* Social Links Section */
.social-text {
  padding-top:50px;
  color: skyblue; /* Match the sidebar's text color */
  font-size: 1.5rem;
  margin-bottom: 10px; /* Space between text and icons */
  font-weight: bold;
  text-align: center;
  color: white
}
.social-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding-bottom: 10px;
  margin-top: 25px
}

.social-links a {
  color: #ecf0f1;
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 0.3s;
}

.social-links a:hover {
  color: #3498db;
}


/* Individual Colors for Icons */
.fa-github {
  color: #fff;
}
.fa-linkedin {
  color: #0077b5;
}
.fa-twitter {
  color: #1da1f2;
}
.fa-facebook {
  color: #3b5998;
}

/* Hover Effects */
.social-link:hover {
  transform: scale(1.2); /* Scale animation */
}
.fa-github:hover {
  color: #6e5494; /* GitHub Purple */
}
.fa-linkedin:hover {
  color: #005582;
}
.fa-twitter:hover {
  color: #007bba;
}
.fa-facebook:hover {
  color: #2d4373;
}

/* Desktop View */
@media (min-width: 769px) {
  .sidebar {
    transform: none; /* Sidebar is always visible on larger screens */
    width: 225px; /* Keep the sidebar at the set width */
  }

  .toggle-btn {
    display: none; /* Hide toggle button on desktop */
  }

  .content {
    margin-left: 250px; /* Sidebar is always visible, so content shifts accordingly */
  }
}

/* Mobile View */
@media (max-width: 768px) {
  /* Sidebar is initially off-screen */
  .sidebar {
    transform: translateX(-100%);
  }

  /* Show sidebar when not collapsed */
  .sidebar:not(.sidebar-collapsed) {
    transform: translateX(0);
  }

  /* Show toggle button on mobile */
  .toggle-btn {
    display: block;
  }

  /* Content area adjusts when sidebar is collapsed */
  .content {
    margin-left: 0;
  }

  .content-expanded {
    margin-left: 250px; /* Shift content when sidebar is expanded */
  }
}
</style>
