<template>
  <div class="app">
    
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
          <!-- Sidebar -->
    <vue-sidebar-menu :collapsed="isCollapsed" class="sidebar">
      <!-- Add menu items here -->
    </vue-sidebar-menu>

  
      </div>
      <div class="col-md-12" style="padding: 0 !important; background-color: #fff">
          <!-- Main Content Area -->

           <div class="raindrop-container">
    <div v-for="n in drops" :key="n" class="raindrop"></div>
    <div :class="['content', { 'content-expanded': !isCollapsed }]">
      <router-view></router-view>
    </div>
      </div>
        </div>
    </div>
  </div>

  
  </div>
</template>

<!-- <script>
import VueSidebarMenu from './components/SideMenu.vue';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';

export default {
  components: {
    VueSidebarMenu,
  },
  data() {
    return {
      isCollapsed: false, // Initially set the sidebar as expanded
    };
  },
  methods: {
    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed; // Toggle the sidebar state
    },
  },
};
</script> -->
<script>
  import VueSidebarMenu from './components/SideMenu.vue';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
export default {
   components: {
    VueSidebarMenu,
  },
  data() {
    return {
       isCollapsed: true, // Initially set the sidebar as expanded
      drops: Array.from({ length: 100 }, (_, i) => i + 1), // Number of raindrops
    };
  },
  //   methods: {
  //   toggleSidebar() {
  //     this.isCollapsed = !this.isCollapsed; // Toggle the sidebar state
  //   },
  // },
  mounted() {
    this.addRaindropStyles();
  },
  methods: {
     toggleSidebar() {
      this.isCollapsed = !this.isCollapsed; // Toggle the sidebar state
    },
    addRaindropStyles() {
      const drops = document.querySelectorAll(".raindrop");
      drops.forEach((drop) => {
        drop.style.left = `${Math.random() * 100}vw`;
        drop.style.animationDuration = `${Math.random() * 2 + 1}s`; // Random duration
        drop.style.animationDelay = `${Math.random() * 5}s`; // Random delay
      });
    },
  },
};
</script>

<style scoped>
/* Styling for the scrollbar */
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Layout container */
.app {
  display: flex;
  flex-direction: row;
}

/* HomePage Styling */
.app {
  /*width: 100%;*/
  height: 100vh; 
  background-image: url('@/assets/images/imran02.jpg'); /* Replace with your image path */
  background-size: cover; /* Ensure the image covers the entire viewport */
  background-position: center top; /* Center the image */
  background-repeat: no-repeat; /* Prevent tiling */
   display: flex; 
  flex-direction: column;
  justify-content: right;
  align-items: left;
  text-align: left;
  padding: 0;
  margin: 0;
}

/* Sidebar Styling */
.sidebar {
  width: 250px; /* Fixed width for the sidebar */
  transition: transform 0.3s ease, width 0.3s ease;
  height: 100vh;
  background: #333;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
    z-index: 10;
  padding-top: 10px; /* To ensure space for content when collapsed */
}



/* Content area */
.content {
  flex: 1;
  margin-left: 250px; /* Adjust margin to accommodate sidebar */
  transition: margin-left 0.3s ease;
}

.content-expanded {
  margin-left: 0; /* No margin when sidebar is collapsed */
}

.raindrop-container {
   z-index: 1;
  position: relative;
/*  width: 100%;
  height: 100vh;*/
  overflow: hidden;
  background: linear-gradient(to bottom, #5f9ea0, #000);
}

.raindrop {
  position: absolute;
  width: 2px;
  height: 5px;
  background-color: white;
  opacity: 0.8;
  animation: fall linear infinite;
}

@keyframes fall {
  from {
    transform: translateY(-100%);
    opacity: 1;
  }
  to {
    transform: translateY(100vh);
    opacity: 0.5;
  }
}
</style>
